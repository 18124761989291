/* eslint-disable @typescript-eslint/naming-convention */
import { getConvertedPrice } from '@src/utils';
import { isEmpty } from 'lodash';
import { Subscription } from '../../../../gatsby-theme-engagement/src/backend';
import { DOLLAR, OTHER_CURRENCIES, TAX_INCLUDED_CURRENCIES } from '../../constants/currency';
import { SUBSCRIPTION_STATES } from '../../constants/subscription';
import { getBillingPeriod } from '../../utils/currencyPromotions';
import { formatDate, isSubscriptionOnHold } from '../../utils/subscriptions';
import { priceFormat } from '../../utils/utilities';
import { Tier } from '../../../../../src/type/subscriptions';
import { PrismicCommonMemberPromoOfferData } from './types';

interface SubscriptionDescriptions {
  state: string;
  active: string;
  canceled: string;
  pendingCancellation: JSX.Element;
  onHold: string;
}

export const capitaliseFirstChar = (text: string) =>
  `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

export const formatSubscriptionDetail = (
  prismicDescription?: string | null,
  tier?: string | null,
  interval?: string
) => {
  if (!prismicDescription || !tier) {
    return '';
  }

  const prismicPlaceholder = {
    interval: '{interval}',
    tier: '{tier}',
  };

  // Add dash in the front
  const formattedInterval = interval ? `- ${interval}` : '';

  // Replace {interval} with actual interval and {tier} with actual tier
  const description = prismicDescription
    .replace(prismicPlaceholder.tier, capitaliseFirstChar(tier))
    .replace(prismicPlaceholder.interval, formattedInterval);

  return description;
};

export const formatTrialingTitle = (defaultTitle: string, trialingText: string) =>
  `${defaultTitle} ${trialingText}`;

export const formatActiveDesc = (
  prismicDescription?: string | null,
  subscription_status_renewal?: string | null,
  subscription?: Subscription,
  isRolloverAllowed?: boolean
) => {
  const { currency, preview_renewal, current_period_ends_at, product_handle, default_to_monthly } =
    subscription || {};

  if (!prismicDescription || !currency || !preview_renewal || !current_period_ends_at) {
    return '';
  }

  let renewsAt;

  if (isRolloverAllowed) {
    if (product_handle?.includes('3monthly') && !default_to_monthly) {
      renewsAt = ' every three months.';
    }

    if (default_to_monthly) {
      renewsAt = ' monthly.';
    } else {
      renewsAt = ' annually.';
    }
  } else if (product_handle?.includes('3monthly')) {
    renewsAt = ' every three months.';
  } else if (product_handle?.includes('annual')) {
    renewsAt = ' annually.';
  } else {
    renewsAt = ' monthly.';
  }

  const prismicPlaceHolders = {
    amount: '{amount}',
    date: '{date}',
  };

  const cents = preview_renewal?.totalInCentsTaxExclusive;
  const amount = priceFormat({ currency, cents, addTax: false, toFixedNumber: false });
  const date = formatDate(current_period_ends_at!);

  // Replace {amount} with actual amount and {date} with actual date
  const description = prismicDescription
    .replace(prismicPlaceHolders.amount, amount)
    .replace(prismicPlaceHolders.date, date);

  return `${description} ${subscription_status_renewal}${renewsAt}`;
};

export const formatPendingCancelDesc = (
  description?: string | null,
  subscription?: Subscription
) => {
  const cancellationDate = subscription?.current_period_ends_at;

  if (!cancellationDate || !description) {
    return '';
  }

  const date = formatDate(cancellationDate);
  const prismicDatePlaceHolder = '{date}';

  return description.replace(prismicDatePlaceHolder, date);
};

export const getRetailDescription = ({
  state,
  active,
  canceled,
  pendingCancellation,
  onHold,
}: SubscriptionDescriptions): string => {
  if (isSubscriptionOnHold(state)) {
    return onHold;
  }

  const descriptionMap: Record<string, string> = {
    [SUBSCRIPTION_STATES.ACTIVE]: active,
    [SUBSCRIPTION_STATES.TRIALING]: active,
    [SUBSCRIPTION_STATES.CANCELED]: canceled,
    [SUBSCRIPTION_STATES.ON_DELAYED_CANCELLATION]: pendingCancellation,
  };

  return descriptionMap[state] || '';
};

export const getFixedTermDesc = ({
  state,
  canceled,
  pendingCancellation,
}: Omit<SubscriptionDescriptions, 'active' | 'onHold'>): string => {
  const descriptionMap: Record<string, string> = {
    [SUBSCRIPTION_STATES.ACTIVE]: pendingCancellation,
    [SUBSCRIPTION_STATES.ON_DELAYED_CANCELLATION]: pendingCancellation,
    [SUBSCRIPTION_STATES.CANCELED]: canceled,
  };

  return descriptionMap[state] || '';
};

export const getRecurringDesc = ({
  state,
  active,
  canceled,
}: Omit<SubscriptionDescriptions, 'onHold' | 'pendingCancellation'>): string => {
  const descriptionMap: Record<string, string> = {
    [SUBSCRIPTION_STATES.ACTIVE]: active,
    [SUBSCRIPTION_STATES.ON_DELAYED_CANCELLATION]: canceled,
    [SUBSCRIPTION_STATES.CANCELED]: canceled,
  };

  return descriptionMap[state] || '';
};

export const getResellerdesc = ({
  state,
  isRecurring,
  active,
  canceled,
  pendingCancellation,
  onHold,
}: SubscriptionDescriptions & {
  isRecurring: boolean;
}): string => {
  if (isSubscriptionOnHold(state)) {
    return onHold;
  }

  const fixedTermDescription = getFixedTermDesc({
    state,
    canceled,
    pendingCancellation,
  });

  const recurringDescription = getRecurringDesc({
    state,
    active,
    canceled,
  });

  return isRecurring ? recurringDescription : fixedTermDescription;
};

export const getIapSubscriptionInfo = (
  userSignupChannel?: string | null,
  prismicIAPData?: Queries.PrismicAccountOverviewPageDataIapSubscriptionsItem[]
) => prismicIAPData?.find((iap) => iap.signup_channel === userSignupChannel?.toLowerCase());

export const formatCurrentBillingDetail = ({
  subscription,
  prismicData,
  interval,
}: {
  subscription: Subscription;
  prismicData: Pick<
    Queries.PrismicAccountOverviewPageData,
    'includes_tax' | 'current_billing_detail' | 'month' | 'year' | 'three_month'
  >;
  interval: number;
}) => {
  if (isEmpty(prismicData) && !subscription?.currency) {
    return '';
  }

  const { includes_tax, current_billing_detail, month, year, three_month } = prismicData;

  const prismicPlaceHolders = {
    interval: '{interval}',
    price: '{price}',
  };

  const price = formatPrice(subscription);
  const formattedInterval = getBillingPeriod(interval, {
    monthly: month,
    quarterly: three_month,
    yearly: year,
  });

  const includeTax = formatIncludeTax(subscription, includes_tax);

  const description = (current_billing_detail || '')
    .replace(prismicPlaceHolders.price, price)
    .replace(prismicPlaceHolders.interval, formattedInterval);

  return `${description} ${includeTax}`;
};

export const formatNextBillingPriceDetail = ({
  subscription,
  prismicData,
  interval,
}: {
  subscription: Subscription;
  prismicData: Pick<
    Queries.PrismicAccountOverviewPageData,
    'next_billing_detail' | 'month' | 'year' | 'three_month'
  >;
  interval: number;
}) => {
  const { currency, current_period_ends_at } = subscription || {};
  if (isEmpty(prismicData) || !currency || !current_period_ends_at) {
    return '';
  }

  const prismicPlaceHolders = {
    interval: '{interval}',
    price: '{price}',
    date: '{date}',
  };

  const billingPrice = formatPrice(subscription);
  const billingInterval = formatBillingInterval(interval, prismicData);
  const billingDate = formatDate(current_period_ends_at);

  const description = (prismicData?.next_billing_detail || '')
    .replace(prismicPlaceHolders.price, billingPrice)
    .replace(prismicPlaceHolders.interval, billingInterval)
    .replace(prismicPlaceHolders.date, billingDate);

  return description;
};

export const formatPrice = (subscription: Subscription) => {
  const { currency, preview_renewal } = subscription || {};

  const isTaxExcluded = TAX_INCLUDED_CURRENCIES[currency as keyof typeof TAX_INCLUDED_CURRENCIES];

  const priceToConvert = isTaxExcluded
    ? preview_renewal?.totalInCentsTaxExclusive
    : preview_renewal?.totalInCentsTaxInclusive;

  const dollarSymbol = OTHER_CURRENCIES[currency as keyof typeof OTHER_CURRENCIES] || DOLLAR;
  const price = getConvertedPrice(priceToConvert, currency);
  return `${currency} ${dollarSymbol}${price}`;
};

const formatBillingInterval = (
  interval: number,
  prismicData: Pick<Queries.PrismicAccountOverviewPageData, 'month' | 'year' | 'three_month'>
) => {
  const { month, year, three_month } = prismicData;

  const formattedInterval = getBillingPeriod(interval, {
    monthly: month,
    quarterly: three_month,
    yearly: year,
  });

  return formattedInterval;
};

export const formatIncludeTax = (subscription: Subscription, text?: string | null) => {
  const { currency } = subscription || {};

  if (!currency || !text) {
    return '';
  }
  // If the currency is not in the TAX_INCLUDED_CURRENCIES, then the text is tax excluded
  const isTaxExcluded = TAX_INCLUDED_CURRENCIES[currency as keyof typeof TAX_INCLUDED_CURRENCIES];

  return !isTaxExcluded ? text : '';
};

export const generateCustomerIOScript = ({
  email,
  vimeoUserId,
  productName = '',
}: {
  email?: string | null;
  vimeoUserId?: string | null;
  productName?: string | null;
}) => {
  const scripts =
    process.env.GATSBY_RT_09_11_2023_CUSTOMERIO_SITE_ID && email && vimeoUserId
      ? [
          {
            type: 'text/javascript',
            innerHTML: `var _cio = _cio || [];
            (function () {
              if (!document.getElementById('cio-tracker')) {
              var a, b, c;
              a = function (f) {
                return function () {
                  _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
                };
              };
              b = ['load', 'identify', 'sidentify', 'track', 'page'];
              for (c = 0; c < b.length; c++) {
                _cio[b[c]] = a(b[c]);
              }
              var t = document.createElement('script'),
                s = document.getElementsByTagName('script')[0];
              t.async = true;
              t.id = 'cio-tracker';
              t.setAttribute('data-site-id', '${process.env.GATSBY_RT_09_11_2023_CUSTOMERIO_SITE_ID}');
              t.setAttribute('data-use-in-app', 'true');
              t.src = 'https://assets.customer.io/assets/track.js';
              s.parentNode.insertBefore(t, s);
            }
            })();
            _cio.identify({
              id: '${vimeoUserId}',
              email: '${email}',
              plan_name: '${productName}',
            });
          `,
          },
        ]
      : [];

  return scripts;
};

export const getProductName = (tier: Tier, subscription: Subscription) => {
  const { interval, interval_unit } = subscription?.product?.product_price_point || {};

  const monthlyIntervalUnit = interval_unit?.toLowerCase() === 'month';

  if (!interval || !monthlyIntervalUnit) {
    return;
  }

  const period = getBillingPeriod(interval, {
    monthly: 'monthly',
    quarterly: '3-monthly',
    yearly: 'annually',
  });

  return `${tier}_${period}`;
};

export const mapOfferByProductName = (
  prismicData: PrismicCommonMemberPromoOfferData[],
  productName?: string
) => prismicData.find((data) => data.subscription_type === productName);

export const checkOfferCountryEligibility = (
  userCountry?: string | null,
  availableCountryList?: string | null
) => {
  if (!userCountry) {
    return false;
  }

  const countryList = getCountryList(availableCountryList);

  return countryList?.includes(userCountry.toLowerCase());
};

const getCountryList = (countryInString?: string | null) => {
  const seperator = '|';
  // Remove all white spaces
  const trimmedString = countryInString?.replace(/ /g, '');
  return trimmedString?.trim()?.split(seperator);
};
