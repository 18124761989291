import React, { FC, useState, useEffect, useContext, useRef } from 'react';
import { AlbumCard } from '@lesmills-international/components';

import { userContext } from '@context';
import {
  isVimeoUrl,
  getExploreAlbumCardSlideAmount,
  getHomeAlbumCardSlideAmount,
  getSlideSpace,
  formatHeroEquipments,
  formatDuration,
  formatTimeDuration,
} from '@services';
import { useWindowSize } from '@hooks';
import Carousel from '@components/atoms/carousel/Carousel';
import { usePersonalizeWorkoutQuery } from '@backend';
import { contentClickTracking } from '@src/utils';
import PageName from '../../../types/pageName';

import {
  AlbumsCarouselSliceWrapper,
  TitleWrapper,
  Title,
  ViewAllLink,
  CarouselWrapper,
} from '../albumsCarouselSlice/style';

interface Props {
  content: Queries.PrismicNewWebHomepageDataBodyHomepageHeroCarousel;
  analytics: {
    contentListTitle: string;
    contentListIndex: number;
    contentLocation: string;
  };
  pageName: PageName;
}

enum AspectRatio {
  landscape_16_9 = 'landscape',
  portrait_3_4 = 'portrait',
  square_1_1 = 'square',
}

enum AlbumCardLabel {
  workout = 'WORKOUT',
  video = 'VIDEO',
  audio = 'AUDIO',
  plan = 'PLAN',
  challenge = 'CHALLENGE',
  program = 'PROGRAM',
  article = 'ARTICLE',
  pdf = 'PDF',
  collection = 'COLLECTION',
  promotion = 'PROMOTION',
  specialOffer = 'SPECIAL OFFER',
  event = 'EVENT',
  new = 'NEW',
  limitedTime = 'LIMITED TIME',
  socials = 'SOCIALS',
}

enum TargetUser {
  existingOnly = 'existing only',
  newOnly = 'new only',
  both = 'both',
}

const PRISMIC_CONTENT_LENGTH = 3;
const HERO_CONTENT_LENGTH = 12;

const AlbumAndWorkoutCarousel: FC<Props> = ({ content, analytics, pageName }) => {
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState(3);
  const [carouselSlidesSpace, setCarouselSlidesSpace] = useState(8);
  const [albumCards, setAlbumCards] = useState<JSX.Element[]>([]);

  const { isNewUser, userEntitlements } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;
  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();
  const isFromHomePage = pageName === PageName.HOME;

  const hideForOldUser = content.slice_label === 'new_user_only' && !isNewUser;

  const { data: personalizeWorkouts, loading: personalizeWorkoutsLoading } =
    usePersonalizeWorkoutQuery({
      skip: hideForOldUser,
    });

  useEffect(() => {
    getAlbumsData(personalizeWorkouts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowInnerWidth, personalizeWorkouts]);

  const getAlbumsData = (personalizeWorkoutsArgs) => {
    if (carouselRef && carouselRef.current) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = isFromHomePage
        ? getHomeAlbumCardSlideAmount(carouselWrapperWidth)
        : getExploreAlbumCardSlideAmount(carouselWrapperWidth);

      // Filter out null workout
      let personalizeWorkoutsAndPrismicData = personalizeWorkoutsArgs?.personalizeWorkout.filter(
        (workout) => workout != null
      );

      const targetUser = {};
      content?.items?.forEach((item) => {
        if (item?.target_user) {
          targetUser[item?.target_user] = [...(targetUser[item?.target_user] || []), item];
        }
      });

      let prismicContentArray = [];

      if (isNewUser) {
        prismicContentArray = targetUser[TargetUser.newOnly]
          ? targetUser[TargetUser.newOnly].concat(targetUser[TargetUser.both])
          : targetUser[TargetUser.both];
      } else {
        prismicContentArray = targetUser[TargetUser.existingOnly]
          ? targetUser[TargetUser.existingOnly].concat(targetUser[TargetUser.both])
          : targetUser[TargetUser.both];
      }

      if (personalizeWorkoutsAndPrismicData) {
        // insert Prismic data to 1, 3, 5 index of personalizeWorkouts
        prismicContentArray?.slice(0, PRISMIC_CONTENT_LENGTH).forEach((item, index) => {
          if (item) {
            personalizeWorkoutsAndPrismicData?.splice(index * 2 + 1, 0, item);
          }
        });
      } else {
        personalizeWorkoutsAndPrismicData = prismicContentArray || [];
      }

      const albumCardArray: JSX.Element[] = [];

      personalizeWorkoutsAndPrismicData?.slice(0, HERO_CONTENT_LENGTH).forEach((item, index) => {
        let albumCardProps = {};
        // Keep both fields for backward compatibility [LPE-3494]
        const isPrismicContent = item?.is_prismic_content || item?.prismic_content;
        const getPath = () =>
          isPrismicContent
            ? isVimeoUrl(item?.detail_link)
              ? `${item?.detail_link}?ticket=${vimeoTicket}`
              : item?.detail_link
            : `/workout/${encodeURIComponent(item?.workoutName || '')}`;
        const onClick = () => {
          let dataTrackingParams = {};
          if (isPrismicContent) {
            dataTrackingParams = {
              contentIndex: index + 1,
              contentTitle: item?.title,
              contentType: item?.label?.toLowerCase() === 'workout' ? 'video' : 'other',
              contentTopic: item?.topic,
            };
          } else if (item?.__typename.toLowerCase() === 'workout') {
            dataTrackingParams = {
              contentIndex: index + 1,
              contentTitle: item?.workoutName,
              contentType: 'video',
              contentTopic: item?.programName,
            };
          }
          contentClickTracking({ ...analytics, ...dataTrackingParams });
        };
        // eslint-disable-next-line no-underscore-dangle
        if (isPrismicContent) {
          // convert Prismic data to album card props
          albumCardProps = {
            label: item?.label,
            title: item?.title,
            description: item?.description,
            duration: formatTimeDuration(item?.duration_min, item?.duration_sec),
            equipmentIcons: formatHeroEquipments(item) || [],
            backgroundImg: item?.background_image?.url,
            link: getPath(),
            onClick,
          };
        } else {
          // convert personalizeWorkouts to album card props
          albumCardProps = {
            label: AlbumCardLabel.workout,
            title: item?.workoutName,
            backgroundImg: item?.imageWide,
            duration: formatDuration(item?.assets?.items?.[0].duration),
            equipmentIcons: item?.equipment || [],
            link: getPath(),
            onClick,
          };
        }
        albumCardArray.push(
          <AlbumCard
            {...albumCardProps}
            aspectRatio={AspectRatio[content?.primary?.aspect_ratio as keyof typeof AspectRatio]}
          />
        );
      });
      if (albumCardArray.length < slidesPerView) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let i = 0; i < slidesPerView - content?.items.length; i += 1) {
          albumCardArray.push(<div />);
        }
      }
      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlidesSpace(getSlideSpace(carouselWrapperWidth));
      setAlbumCards(albumCardArray);
    }
  };

  if (personalizeWorkoutsLoading) {
    return null;
  }

  if (hideForOldUser) {
    return null;
  }

  return (
    <AlbumsCarouselSliceWrapper>
      <TitleWrapper>
        <Title>{content?.primary?.title}</Title>
        {content?.primary?.view_all_text && (
          <ViewAllLink href={`${content?.primary?.view_all_link}?ticket=${vimeoTicket}`}>
            {content?.primary?.view_all_text}
          </ViewAllLink>
        )}
      </TitleWrapper>
      <CarouselWrapper ref={carouselRef} noRightPadding={isFromHomePage}>
        <Carousel
          items={albumCards}
          slidesPerView={carouselSlidesPerView}
          slidesPerGroup={carouselSlidesPerView}
          spaceBetween={carouselSlidesSpace}
          className={`${isFromHomePage ? 'home--carousel--wrapper' : ''}`}
          alwaysShowArrowButton={isFromHomePage}
        />
      </CarouselWrapper>
    </AlbumsCarouselSliceWrapper>
  );
};

export default AlbumAndWorkoutCarousel;
