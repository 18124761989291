import { Product, Subscription } from '../../../gatsby-theme-engagement/src/backend';

/* eslint-disable import/prefer-default-export */
const normalizeCurrentPlanData = (subscription: Subscription): Product => {
  const { product } = subscription;
  const plan = {
    country_code: product?.country_code,
    currency: product?.currency,
    id: product?.product_handle,
    product_handle: subscription?.product_handle ?? product?.product_handle,
    interval: product?.product_price_point?.interval,
    interval_unit: product?.product_price_point?.interval_unit,
    name: product?.name,
    price: product?.product_price_point?.price_in_cents,
    trial_interval: product?.product_price_point?.trial_interval,
    trial_interval_unit: product?.product_price_point?.trial_interval_unit,
    sortOrder: product?.product_price_point?.interval,
    product_price_point: product?.product_price_point,
    tier: product?.tier,
    title: product?.tier?.internal_tier_id,
  };

  return plan;
};

export default normalizeCurrentPlanData;
